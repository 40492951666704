<template>
  <div>
    <b-modal
      id="filter"
      ok-only
      centered
      size="md"
      content-class="shadow"
      hide-footer
    >
    <template #modal-title> <div style="font-size:24px; font-weight:600;">Filter</div></template>
      <div >
        <b-form-row>
          <b-col>
            
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;">Status Sekretariat</p>
              </b-col>:<b-col sm="6">
                <b-form-select
                  type="text"
                  v-model="selectedSekretariat"
                  placeholder="Silahkan Pilih Status Sekretariat"
                >
                <b-form-select-option :value="item.kode" v-for="(item,index) in listSekretariat" :key="index">{{item.namaLokasi}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;">Propinsi</p>
              </b-col>:<b-col sm="6">
                <b-form-select
                  type="text"
                  v-model="selectedPropinsi"
                  placeholder="Silahkan Pilih Propinsi"
                >
                <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;">Kabupaten/Kota</p>
              </b-col>:<b-col sm="6">
                <b-form-select
                  type="text"
                  v-model="selectedKota"
                  placeholder="Silahkan Pilih Kabupaten/Kota"
                >
                {{ listKota ? '' : ''}}
                <b-form-select-option :value="item.kode" v-for="(item,index) in listKota" :key="index">{{item.nama}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;">Status Lapor</p>
              </b-col>:<b-col sm="6">
                <b-form-select
                  type="text"
                  placeholder="Silahkan Pilih Status Lapor"
                >
                <b-form-select-option :value="1">Belum Lapor</b-form-select-option>
                <b-form-select-option :value="0" >Sudah Lapor</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
        <div class="text-center">
          <b-button
            variant="outline-primary"
            class="mt-16 text-center mr-5"
            @click="$bvModal.hide('filter')"
          >
            Batal
          </b-button>
          <b-button
            variant="primary"
            class="mt-16 text-center"
            @click="$bvModal.hide('filter')"
          >
            Terapkan
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="detail"
      ok-only
      centered
      size="xl"
      content-class="shadow"
      hide-footer
    >
    <template #modal-title> <div style="font-size:24px; font-weight:600;">Detail - Cabang Ormas</div></template>
    {{detailCabang ? '' : ''}}
      <div v-if="detailCabang">
        <b-tabs
          pills
          card
          justified
          v-model="activeTab"
        >

          <b-tab
            active
            title="Profil"
            style="background-color:white;margin-top:0px;"
          >
            <b-row>
              <b-col cols="7">
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Nama Ormas" }}</p>
                  </b-col>:<b-col sm="6" style="white-space: pre-wrap">{{ detailCabang.nama_cabang}}</b-col>
                </b-row>
                <b-row v-if="detailCabang.nama_singkatan">
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Singkatan" }}</p>
                  </b-col>:<b-col sm="6">{{detailCabang.nama_singkatan}}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">
                      {{ "Status Sekretariat" }}
                    </p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.masterLokasiSekretariat">{{ detailCabang.masterLokasiSekretariat.nama_lokasi }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">
                      {{ "Alamat sekretariat" }}
                    </p>
                  </b-col>:<b-col sm="6">{{ detailCabang.alamat_sekretariat }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Kabupaten/Kota" }}</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.wilayahKabupaten">{{ detailCabang.wilayahKabupaten.nama }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Propinsi" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.wilayahProvinsi.nama }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Nomor Telepon" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.no_telp }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Nomor FAX" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.no_fax }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p
                      style="font-weight: 600"
                    >{{ "NPWP" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.npwp }}
                  </b-col>
                </b-row>
              </b-col>
              <!-- <b-col cols="5">
                <b-row>
                  <img
                    src="../../../assets/image/images/ormas_picture.png"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                    alt="abstract"
                  />
                  <img
                    src="../../../assets/image/images/ormas_flag.png"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                    alt="abstract"
                  />
                </b-row>
              </b-col> -->
            </b-row>
          </b-tab>
          <b-tab
            title="Info Pendirian"
            style="background-color:white;margin-top:0px;"
          >
            <b-row>
              <b-col cols="7">
                <!-- <b-row v-for="(item, i) in info" :key="i">
                  <b-col sm="4"
                    ><p style="font-weight: 600">{{ item.label }}</p></b-col
                  >:<b-col sm="6">{{ item.answer }} <b-button v-if="item.label == 'Nomor AKTA Pendirian' || item.label == 'Nomor Surat Pendaftaran' " size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button></b-col>
                </b-row> -->
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Nama Notaris</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.namaNotaris }} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p
                      style="font-weight: 600"
                    >Nomor AKTA Pendirian</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.nomorAkta }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Tanggal AKTA Pendirian</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.tanggalAkta | moment("DD - MM - YYYY")}} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Tempat Pendirian</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.tempatPendirian }} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Tanggal Pendirian</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.tanggalPendirian | moment("DD - MM - YYYY") }} </b-col>
                </b-row>
                <b-row v-if="detailCabang.infoPendirian.masterKesbangpol">
                  <b-col sm="4">
                    <p style="font-weight: 600">Nama Kesbangpol</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.masterKesbangpol.nama }} </b-col>
                </b-row>
                <b-row v-if="detailCabang.infoPendirian.masterKesbangpol">
                  <b-col sm="4">
                    <p style="font-weight: 600">Email Kesbangpol</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.masterKesbangpol.email }} </b-col>
                </b-row>
                <b-row v-if="detailCabang.infoPendirian.masterKesbangpol">
                  <b-col sm="4">
                    <p style="font-weight: 600">Nomor HP Kesbangpol</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.masterKesbangpol.nomorTelepon }} </b-col>
                </b-row>
              </b-col>
              <!-- <b-col cols="5">
                <b-row>
                  <img
                    src="../../../assets/image/images/ormas_picture.png"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                    alt="abstract"
                  />
                  <img
                    src="../../../assets/image/images/ormas_flag.png"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                    alt="abstract"
                  />
                </b-row>
              </b-col> -->
            </b-row>
          </b-tab>
          <b-tab
            title="Struktur Organisasi"
            style="background-color:white;margin-top:0px; padding-left:0px; padding-right:0px;"
          >
            <b-card-text class="pl-0 pr-0">
              <div class="card-body pt-0 pb-3 pl-0 pr-0">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                      <thead>
                        <tr class="text-left">
                          <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                          <th style="min-width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                          <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                          <th style="width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                          <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tempat/Tanggal Lahir</th>
                          <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in detailCabang.pengurusOrmas">
                          <tr v-bind:key="i">
                            <td>
                             <img :src="item.foto" style="width:64px;height:64px;" />  
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.nama_pengurus }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.masterJabatan.nama_jabatan }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.identitas }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.no_hp }}</span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <!--end::Table-->
                  <div class="mt-5">
                    <b-row class="d-flex align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">SK Susunan Pengurus</p>
                      </b-col>:<b-col sm="6"><b-button
                          size="sm"
                          variant="success"
                        >
                          <v-icon
                            style="color:white;"
                            small
                          >mdi-clipboard</v-icon>Lihat File Scan
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab
            title="Rekening"
            style="background-color:white;margin-top:0px;"
          >
            <div class="table-responsive">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px" class="text-capitalize">No</th>
                    <!-- <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                    <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th> -->
                    <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                    <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in detailCabang.rekeningOrmas">
                    <tr v-bind:key="i">
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{ i + 1 }}</span>
                      </td>
                      <!-- <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{detailCabang.masterLokasiSekretariat.nama_lokasi}}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{detailCabang.kabupaten_kota_sekretariat}}</span>
                      </td> -->
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.no_rekening}}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.nama_bank}}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.nama_pemilik_rekening}}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.id_jenis_rekening}}</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-button
              size="lg"
              id="black-btn"
              @click="cetakPDF(detailCabang.id)"
            >Cetak PDF</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- end modal section -->

    <b-row class="mb-5">
      <b-col>
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          <b>Cabang Organisasi</b>
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          size="sm"
          variant="primary"
          @click="$bvModal.show('filter')"
        >
          <v-icon
            style="color:white;"
            small
          >mdi-filter</v-icon>
          Filter
        </b-button>
      </b-col>
    </b-row>
    <div>
      <div
        class="shadow"
        style="border-radius:15px;"
      >
        <div
          class="table-responsive"
          style="border-radius:15px;"
        >
          <table
            class="
          table
          table-head-custom
          table-vertical-center
          table-head-bg
          table-bordered
        "
            style="font-size:14px !important;"
          >
            <thead>
              <tr class="text-left">
                <th style="width: 20px" class="text-capitalize">No.</th>
                <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th>
                <th style="min-width: 50px" class="text-capitalize">Propinsi</th>
                <th style="min-width: 50px" class="text-capitalize">Status</th>
                <th style="min-width: 50px" class="text-capitalize">Aksi</th>
              </tr>
            </thead>
            <tbody v-if="listCabang.length == 0">
                <template>
                    <tr>
                        <td colspan="6"> -- Tidak ada permohonan -- </td>
                    </tr>
                </template>
            </tbody>
            <tbody v-else style="background:white;">
              <template v-for="(item, i) in listCabang">
                <tr v-bind:key="i">
                  <td>
                    <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    font-size-lg
                  ">{{ i + 1}}</span>
                  </td>
                  <td>
                    <span class="
                    text-dark-75
                    text-left
                    font-weight-bolder
                    d-block
                    font-size-lg
                  ">{{ item.masterLokasiSekretariat.nama_lokasi}}</span>
                  </td>
                  <td>
                    <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  " v-if="item.alamat_sekretariat">{{ item.alamat_sekretariat.length > 30 ? item.alamat_sekretariat.substring(0,30) + "..." : item.alamat_sekretariat  }}</span>
                  </td>
                  <td>
                    <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  " v-if="item.wilayahKabupaten">{{ item.wilayahKabupaten.nama }}</span>
                  </td>
                  <td>
                    <span class="
                    text-dark-75
                    text-left
                    font-weight-bolder
                    d-block
                    font-size-lg
                  " v-if="item.wilayahProvinsi">{{ item.wilayahProvinsi.nama }}</span>
                  </td>
                  <td>
                    <span class="
                    text-dark-75
                    font-weight-bolder
                    text-left
                    d-block
                    font-size-lg
                  ">{{ item.status_cabang }}</span>
                  </td>
                  <td>
                    <b-button
                      variant="success"
                      size="sm"
                      @click="getDetailCabang(item.id)"
                    >
                      >> Detail
                    </b-button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
#black-btn {
  background-color: black;
  color: white;
}

.tabs .card-header {
  background-color: #e5e5e5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}
.close{
  position: absolute;
  right: 30px;
}
.nav-item {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
  text-align: center;
  /* margin-right:3.35rem; */
}

.nav-item a {
  display: flex;
  justify-content: center;
}

.nav.nav-pills .nav-item {
  margin-right:10px !important;
  margin-left:10px !important;
}
.modal-header {
  display: flex;
  justify-content: center !important;
  border:none;
}
.modal-body {
  padding-top: 0px;
}
</style>
<script>
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Informasi",
  data() {
    return {
      activeTab: 0,
      listCabang: [],
      detailCabang: null,
      listPropinsi: [],
      listKota: [],
      listSekretariat:[],
      selectedPropinsi: null,
      selectedKota: null,
      selectedSekretariat: null
  }
  },

  mounted() {
    this.getListCabang()
    this.getPropinsi()
    this.getStatusSekretariat()
  },
  watch:{
    selectedPropinsi(to){
      this.getKota(to)
    }
  },
  methods: {
    cetakPDF(id){
      this.$store
        .dispatch("printPDFCabang", id)
        .then((res) => {
          window.open(res.data.meta.urlPath , "_blank");
          })
    },
    getListCabang(){
      this.$store
        .dispatch("getListCabang", null)
        .then((res) => {
          this.$nextTick(() => {
            this.listCabang = res.data.data
          })
          })
    },
    getDetailCabang(id){
      
      this.$store
        .dispatch("getDetailCabang", id)
        .then((res) => {
          this.$nextTick(() =>{
            this.detailCabang = res.data.data
            this.$bvModal.show('detail')
          })
          
          })
    },
    getPropinsi(){
      this.$store
        .dispatch("getPropinsi")
        .then((res) => {
          
           this.$nextTick(() => {
            this.listPropinsi = res.data.data
          })
          })
    },
    getKota(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota = res.data.data
          })
          })
    },
    getStatusSekretariat(){
      this.$store
        .dispatch("getStatusSekretariatOrmas")
        .then((res) => {
          
          this.$nextTick(() => {
            this.listSekretariat = res.data
          })
          })
    },
  },
  computed: {
    flagImage() {
      return process.env.BASE_URL + "media/svg/flags/226-united-states.svg";
    }
  }
};
</script>

<style>
.table.table-head-bg thead tr,
.table.table-head-bg thead th {
  background-color: #333333;
}
.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  color: #ffffff !important;
  text-align: center;
}
.table.table-head-custom tbody tr,
.table.table-head-custom tbody td {
  text-align: center;
}
</style>
